import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import OutlineArrowChevronDown from '@dolstaff/shared/es/icons/OutlineArrowChevronDown';
import cl from 'classnames';
import styled from 'styled-components';

import Context from './context';

const Header = ({ children, className, ...props }) => {
  const { on, toggle } = useContext(Context);
  return (
    <Button
      onClick={toggle}
      className={cl(className, { expanded: on, collapsed: !on })}
      {...props}
    >
      <Text>{children}</Text>
      <AnimatedChevronDown size={20} down={on} />
    </Button>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Header.defaultProps = {};

export const PanelHeader = React.memo(Header);

const AnimatedChevronDown = styled(OutlineArrowChevronDown)`
  transform: rotate(${p => (p.down ? 180 : 0)}deg);
  transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Button = styled.div`
  padding: 16px 24px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &.expanded {
    border-bottom: 1px solid #ebecf0;
  }

}
`;

const Text = styled.div`
  flex: 1;
  text-align: left;
  margin: 0;
  color: #242c34;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
