import React from 'react';
import PropTypes from 'prop-types';

import cl from 'classnames';
import styled from 'styled-components/macro';

import useToggle from 'utils/use-toggle';

import Context from './context';

export const Panel = ({
  children,
  className,
  expanded,
  noToggle,
  collapsedClassName = 'collapsed',
  expandedClassName = 'expanded',
  ...props
}) => {
  const { on, toggle, setOff, setOpen } = useToggle(expanded, noToggle);

  React.useEffect(() => {
    if (!expanded) {
      setOff();
    } else {
      setOpen();
    }
  }, [expanded, setOff, setOpen]);

  return (
    <Container
      className={cl(className, {
        [collapsedClassName]: !on,
        [expandedClassName]: on
      })}
      {...props}
    >
      <Context.Provider value={{ toggle, on }}>{children}</Context.Provider>
    </Container>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  collapsedClassName: PropTypes.string,
  className: PropTypes.string,
  expandedClassName: PropTypes.string,
  expanded: PropTypes.bool,
  noToggle: PropTypes.bool
};

Panel.defaultProps = {};

export default React.memo(Panel);
export * from './header';
export * from './body';

const Container = styled.div`
  border: 1px solid #ebecf0;
  box-sizing: border-box;
  background: #ffffff;
`;
