import { useCallback, useState } from 'react';

export default (defaultValue, noToggle) => {
  const [on, setOn] = useState(defaultValue || false);

  const toggle = useCallback(() => {
    if (noToggle) {
      setOn(true);
      return;
    }
    setOn(!on);
  }, [on, noToggle]);

  const setOff = useCallback(() => {
    setOn(false);
  }, []);

  const setOpen = useCallback(() => {
    setOn(true);
  }, []);

  return { on, off: !on, toggle, setOff, setOpen };
};
